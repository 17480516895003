.tout {
    
    
}
.first-big-row {

    display: flex;
}
.Chatbot{
    flex: 1;
    justify-content: space-between;
    align-content: center;
    
}
.premier{
    width: 900px;
    height: fit-content;
    background-color: #373737;
    margin: 20px 40px;
    color: #f5f5f5;
    border-radius: 10px;
    border: 1px solid #4e4e4e;
    
    align-content: center;
    display: flex;

}

.affich{
    
}

.Title {
    font-size: 30px;
    margin-top: 100px;
    margin-left: 30px;
    
    
    
}
.Tests {
    margin-left: 30px;
    margin-top: 0px;
    
    margin-bottom: 90px;
    color: #848280;
    
    align-items: center;
    
}
.test {
    margin-left: 380px;
    margin-top: 0px;
    border-left: 1px solid #4e4e4e;
    
}

.latestrun {
    font-size: 17px;
    color: #848280;
    margin-left: 20px;
    margin-top: 30px;
    font-weight: 200;
    
}

.correctness {
    font-size: 17px;
    color: #dcdcdc;
    margin-left: 20px;
    margin-top: 40px;
    font-weight: 300;
    display: flex;
    width: 100%;
    

}

.confidence {
    font-size: 17px;
    color: #dcdcdc;
    margin-left: 20px;
    margin-top: 30px;
    font-weight: 300;
    display: flex;

}

.clarity {
    font-size: 17px;
    color: #dcdcdc;
    margin-left: 20px;
    margin-top: 30px;
    font-weight: 300;
    display: flex;
    
}



.circular-chart {
    display: block;
    margin-left: 50px;
    max-width: 10%;
    max-height: 100px;
  }
  
  .circle {
    stroke: #a39760;
    fill: none;
    stroke-width: 2.8;
    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
  }
  
  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }
  .commentun {
    font-size: 18px;
    color: #a39760;
    margin-left: 10px;
    
    font-weight: 300;
    display: flex;
    
  }

  .circular-chart-deux {
    display: block;
    margin-left: 53px;
    max-width: 10%;
    max-height: 100px;
  }
  
  .circle-deux {
    stroke: #858960;
    fill: none;
    stroke-width: 2.8;
    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
  }
  
  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }

  .commentdeux {
    font-size: 18px;
    color: #858960;
    margin-left: 10px;
    
    font-weight: 300;
    display: flex;
    
  }

  .circular-chart-trois {
    display: block;
    margin-left: 85px;
    max-width: 10%;
    max-height: 100px;
  }
  
  .circle-trois {
    stroke: #74ac97;
    fill: none;
    stroke-width: 2.8;
    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
  }
  
  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }

  .comment-trois {
    font-size: 18px;
    color: #74ac97;
    margin-left: 10px;
    
    font-weight: 300;
    display: flex;
    
  }

  .second-box {
    width: 500px;
    height: fit-content;
    background-color: #373737;
    margin: 20px 2px;
    color: #f5f5f5;
    border-radius: 10px;
    border: 1px solid #4e4e4e;
    margin-right: 20px;
    
    
    

  }
  .titre-subject {
    font-size: 30px;
    margin-top: 10px;
    margin-left: 30px;
    font-weight: 320;
    
    
  }

.Tests-matter{
    margin-left: 30px;
    margin-top: 0px;
    
    margin-bottom: 60px;
    color: #848280;
    
    align-items: center;
  }
  
.second-row-latest-test {
    
    border-top: 1px solid #4e4e4e;
  }

.text-second-row {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 15px;
    margin-left: 30px;
    color:#848280;
}
.third-row-percentages {
    border-top: 1px solid #4e4e4e;
    display: flex;
    
    
    height: 80px;
}
.third-row-Correctness {
    
    
    border-right: 1px solid #4e4e4e;
    
}
.third-row-Confidence {
    
    border-right: 1px solid #4e4e4e;
}


.text-correctness {
    margin-left: 30px;
    margin-right: 20px;
    color:#848280;
    font-weight: 300;
    margin-top: 10px;
    margin-right: 25px;
}

.text-confidence {
    margin-left: 25px;
    margin-right: 20px;
    color:#848280;
    font-weight: 300;
    margin-right: 25px;
    margin-top: 10px;
}

.text-clarity {
    margin-left: 40px;
    color:#848280;
    font-weight: 300;
    margin-top: 10px;
    margin-right: 25px;
}

.percentage-value-correctness{
    margin-left: 55px;
    margin-right: 20px;
    font-size: 30px;

}
.correctness-line{
    border: 1px solid #dabe43;
    width: 50%;
    margin-left: 35px;
}

.percentage-value-confidence {
    margin-left: 55px;
    margin-right: 20px;
    font-size: 30px;
}

.confidence-line {
    border: 1px solid #c6b850;
    width: 50%;
    margin-left: 35px;

}
.percentage-value-clarity{
    margin-left: 55px;
    margin-right: 20px;
    font-size: 30px;
}

.clarity-line {
    border: 1px solid #a9b873;
    width: 50%;
    margin-left: 40px;

}

.second-big-row {
    display: flex;
    margin-left: 40px;
}

.first-box-second-row {
    width: 450px;
    height: fit-content;
    background-color: #373737;
    margin: 20px 2px;
    color: #f5f5f5;
    border-radius: 10px;
    border: 1px solid #4e4e4e;
 }
 
 .correctness-line-second{
    border: 1px solid #afc67a
    ;
    width: 50%;
    margin-left: 35px;
}

.confidence-line-second {
    border: 1px solid #4a8472
    ;
    width: 50%;
    margin-left: 35px;

}

.clarity-line-second {
    border: 1px solid #4d937b
    ;
    width: 50%;
    margin-left: 40px;

}

.second-box-second-row {
    width: 450px;
    height: fit-content;
    background-color: #373737;
    margin: 20px 2px;
    color: #f5f5f5;
    border-radius: 10px;
    border: 1px solid #4e4e4e;
    margin-left: 20px;
 }


 .correctness-line-third{
    border: 1px solid #ac3d3d

    ;
    width: 50%;
    margin-left: 35px;
}

.confidence-line-third {
    border: 1px solid #bb3f3f
    ;
    width: 50%;
    margin-left: 35px;

}

.clarity-line-third {
    border: 1px solid #b84239
    ;
    width: 50%;
    margin-left: 40px;

}

.third-box-second-row {
    width: 450px;
    height: fit-content;
    background-color: #373737;
    margin: 20px 2px;
    color: #f5f5f5;
    border-radius: 10px;
    border: 1px solid #4e4e4e;
    margin-left: 20px;
    margin-right: 20px;
 }

 .correctness-line-fourth{
    border: 1px solid #a89639;
    width: 50%;
    margin-left: 35px;
}

.confidence-line-fourth {
    border: 1px solid #bccf85;
    width: 50%;
    margin-left: 35px;

}

.clarity-line-fourth {
    border: 1px solid #d3b049;
    width: 50%;
    margin-left: 40px;

}

.box-third-row{
    width: 400px;
    height: fit-content;
    background-color: #373737;
    margin: 20px 40px;
    color: #f5f5f5;
    border-radius: 10px;
    border: 1px solid #4e4e4e;
    
    align-content: center;
    display: flex;

}

.Title-1 {
    font-size: 30px;
    margin-top: 10px;
    margin-left: 30px;
    font-weight: 300;
    
    
}

.Tests-1 {
    margin-left: 30px;
    margin-top: 10px;
    
    margin-bottom: 30px;
    color: #848280;
    
    align-items: center;
    
}
.third-big-row{
    display: flex;
}