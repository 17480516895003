.topbar{
    width: 100%;
    height: 50px;
    background-color: #1c141c;
}
.topbarWrapper{
    height: 100%;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.logo{
    font-weight: bold;
    font-size: 30px;
    color: #d3dad7;
    cursor: pointer;
}